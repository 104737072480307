import { useState, useEffect, useContext } from "react"
import icon_happy from './image/icon_happy.webp';
import './style.scss';
import { useNavigate, useLocation } from 'react-router-dom';






const Welcome = () => {

  const navigate = useNavigate();

  // для получения переменной из react dom
  const location = useLocation();
  const { register } = location.state || {}; // Достаем переменную из состояния


  // Переход на quiz
  const handleClick = () => {
    navigate("/quiz", { replace: true });
  };


  // Проверяем есть ли register если нет то переходим в my cabinet
  useEffect(() => {

    if (register !== true) {
      navigate("/my_cabinet", { replace: true });
    }
  }, [])




  return (
    <div className='bg_quiz'>

      <div className='wrapper_quiz'>

        <div className='quiz'>



          <div className='title_quiz'>
            Поздравляем!
            <img src={icon_happy} alt="Happy Icon" />
          </div>

          <div className='reg'>Вы успешно зарегистрировались</div>
          <div className='post_mail'>Пароль отправили на вашу почту</div>

          <button



            onClick={handleClick}


            className='button_quiz'>
            Далее
          </button>



        </div>

      </div>


    </div >
  )

}





export default Welcome;









