
// Форма
import { useForm } from 'react-hook-form';
// инпут
import { Controller } from 'react-hook-form'; // контроллер для инпута







const UserNameQuiz = ({ isDisabled, handleChange }) => {


  // -----Формы-----
  // настройки для формы
  const { register,
    control,
    handleSubmit,
    setError,
    formState: { errors }

  } = useForm({
    mode: 'onBlur',
  });




  return (

    <>

      <div className='title_quiz'>
        Давайте знакомиться
      </div>

      {/* контроллер от формы */}
      <Controller
        control={control}
        name='username'
        render={({ field: { onChange, onBlur, value = '' } }) => (



          <input
            className='input_quiz'
            type='text'
            onBlur={onBlur}

            {...register('username', {
              required: 'Минимум 2 символа',
              minLength: {
                value: 2,
                message: 'Минимум 2 символa'
              }
            })
            }

            // onChange={e => onChange(e.target.value)}
            // onChangeText={(text) => onChange(text)}
            onChange={onChange}
            value={value}
            placeholder={'Как вас зовут?'}
          />

        )}
      />

      {/*ошибка*/}
      {errors?.username && <div className='error_quiz'>
        {errors?.username?.message}
      </div>}

      <button
        onClick={handleSubmit(handleChange)}
        className='button_quiz'
        disabled={isDisabled}>
        Далее
      </button>


    </>


  );
};

export default UserNameQuiz;





























