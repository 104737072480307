// Проверяет есть ли токен?
// Старый он или нет? Если старый то обновляет
// Прописывает ключ в заголовки

import axios from "axios";
import { jwtDecode } from "jwt-decode";



// Axios instance с базовой конфигурацией
const TokenManagerMiddleware = axios.create({
  // удалил так на сервере выскакивала ошибка CORS
  // baseURL: "http://localhost:8000/",
});

// Middleware для добавления токенов в заголовки запросов
TokenManagerMiddleware.interceptors.request.use(
  async (config) => {

    console.log('--ddddddddddddddЗапустилась Middleware')

    if (!config.headers.Authorization) {
      console.log('--нет заголовка')
      const accessToken = localStorage.getItem("accessToken");

      if (accessToken && isTokenExpired(accessToken)) {
        // Попытка обновить access токен с помощью refresh токена
        const newAccessToken = await refreshAccessToken();
        if (newAccessToken) {
          config.headers.Authorization = `Bearer ${newAccessToken}`;
        }
      } else if (accessToken) {
        console.log('--есть заголовок')
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Проверка, истек ли access токен
const isTokenExpired = (token) => {
  const decodedToken = jwtDecode(token);
  return decodedToken.exp < Date.now() / 1000;
};

// Обновление access токена
const refreshAccessToken = async () => {

  try {
    console.log('-Запустилась refresh-функция')
    const refreshToken = localStorage.getItem("refreshToken");
    const response = await axios.post("/api/token/refresh/", { 'refresh': refreshToken });


    const { access: newAccessToken, refresh: newRefreshToken } = response.data;
    localStorage.setItem("accessToken", newAccessToken);
    localStorage.setItem("refreshToken", newRefreshToken);

    return newAccessToken;
  } catch (error) {
    console.error("refreshToken устарел, выход из системы", error);
    // Обработка ошибок, например, редирект на страницу логина

    localStorage.removeItem('refreshToken');
    localStorage.removeItem('accessToken');

    //Переход в админку или в кабинет (c перезагрузкой)
    window.location.href = '/login';
    // return null;
  }
};

export default TokenManagerMiddleware;

