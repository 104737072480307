import { useState } from 'react'
import TokenManagerMiddleware from '../../../context/TokenManagerMiddleware'
const InputTag = () => {

  const [formNote, setFormNote] = useState({
    title: "",
  })

  function createNote(event) {

    // отправляем запрос через middleware (требуется ключ админа)
    TokenManagerMiddleware({
      method: "POST",
      url: "/api/new_tag/",
      data: {
        title: formNote.title,
      },
      headers: { 'content-type': 'multipart/form-data' }
    })

    setFormNote(({
      title: "",
    }))

    window.location.reload(); //Перезагрузка страницы
    event.preventDefault()
  }

  function handleChange(event) {
    const { value, name } = event.target
    setFormNote(prevNote => ({
      ...prevNote, [name]: value
    })
    )
  }

  return (
    <div className="container_admin_form">
      <h3>Новый тег</h3>
      <form method="post" encType="multpart/form-data">
        <div className="column_one">
          <div className="count_one">1</div>
          <div className="column_one_res">
            <input onChange={handleChange} text={formNote.title} name="title" type="text" className="admin_name" maxLength="200" placeholder="Введите новый тег" value={formNote.title} required="" />
          </div>
        </div>
        <button onClick={createNote} className="admin_button_cat">Отправить</button>
      </form>
    </div>
  )
}

export { InputTag }
