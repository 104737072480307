import { createContext, useContext, useEffect, useMemo, useState } from "react";
import TokenManagerMiddleware from './TokenManagerMiddleware'


// Context переменная которая может проходить сквозь все компоненты
export const AuthContext = createContext();


const AuthProvider = ({ children }) => {


  const [accessToken, setAccessToken] = useState(localStorage.getItem('accessToken'));
  const [refreshToken, setRefreshToken] = useState(localStorage.getItem('refreshToken'));

  const [isLoading, setIsLoading] = useState(false); // 


  const [profile, setProfile] = useState(
    {
      username: '',
      courses: [], //если не вставить эти данные может умереть
      title: '',
    }
  )

  const [courses, setCourses] = useState(null)




  //Загружаем данные (используется в загрузке данных в my_cabinet.jsx)
  async function Reload() {
    console.log('--Запустился Reload - загружаем данные')
    try {
      setIsLoading(true)

      //загружаем профиль если не грузится делаем refresh токена
      await TokenManagerMiddleware.get('/api/profile/')
        .then(response => {
          const data = response.data

          // сохраняем в LocalStorage данные профиля
          // localStorage.setItem('profile', JSON.stringify(data));

          // сохраняем данные в useState
          setProfile(data)

          setIsLoading(false) // включаем кнопку
        })
        .catch(error => {
          console.error('Ошибка:', error);
        });

    } catch (error) {
      console.log('Access токен устарел');
    }
  }





  // Cохранение токенов в useStat и localStorage 
  // используется в login и register
  // обрати внимание на await в reload (ждём пока функция доработает)
  async function saveTokens(tokens) {
    console.log('---Запустилась saveTokens-функция')

    // Сохраняем токены AsyncStorage
    localStorage.setItem('refreshToken', tokens.refresh);
    localStorage.setItem('accessToken', tokens.access);

    // Сохраняем токены в useState
    setRefreshToken(tokens.refresh) // refresh токен
    setAccessToken(tokens.access) // access токен
    await Reload()
  }






  // Выход
  const logout = () => {
    console.log('---Выход')

    localStorage.removeItem('refreshToken');
    localStorage.removeItem('accessToken');

    // Удаляем токены из useState
    setRefreshToken(null) // refresh токен
    setAccessToken(null) // access токен
  }



  //Автозапуск
  useEffect(() => {

    console.log('---Запустилась useEffect функция')


    const refreshToken = localStorage.getItem('refreshToken');

    if (refreshToken) {
      console.log('Токен существует:', refreshToken);
      Reload()
    } else {
      console.log('Токен не найден');
    }

  }, []);




  // Передаём контекст дочерним компонентам
  return (
    <AuthContext.Provider value={{

      accessToken,
      saveTokens,
      logout,
      Reload,
      profile,
      courses,
      isLoading,

    }}>

      {children}

    </AuthContext.Provider>
  );
};


export default AuthProvider;
