import './App.css';

import AuthProvider from "./context/authContext";
import Routes from "./routes/IndexRoute";

function App() {
  return (
    <AuthProvider>
      <Routes />
    </AuthProvider>
  );
}

export const API_URL = "http://localhost:8000";
export default App;
