import './style.scss';
import { NavLink, Link } from 'react-router-dom'
import logo from './images/logo.svg'
import { useState, useEffect } from 'react'
import { ButtonMobileMenu } from '../buttonmobilemenu/ButtonMobileMenu'
import { CSSTransition } from 'react-transition-group'

const AdmNavbar = () => {

  const [menu, setMenu] = useState(false)

  const [windowSize, setWindowSize] = useState([
    window.innerWidth
  ]);

  // Вычисляем ширину сайта
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth])

    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  // Если <= 800 то выключаем меню  
  useEffect(() => {
    if (windowSize[0] <= 800) {
      setMenu(false)
    }
    else {
      setMenu(true)
    }
  }, [windowSize[0]])

  // Кнопка всплывания меню
  const clickMenu = () => {
    setMenu(!menu)
  }

  // Назваине класса для ширины появления мобильного меню
  const classna = 'admnavbar xx';

  return (
    <>
      <div className="admin_header">
        <div className="admin_header_container">
          <Link to="/adm/new_course" className="logo" id="logo"><img src={logo} /> </Link>

          <CSSTransition in={menu} timeout={300}
            classNames='alert' unmountOnExit>
            <ul className="admin_menu">
              <li><NavLink to="/adm/new_course">новый <br />курс</NavLink></li>
              <li><NavLink to="/adm/new_paid_video">новое <br />видео</NavLink></li>
              <li><NavLink to="/adm/new_article">новая <br />статья</NavLink></li>
              <li><NavLink to="/adm/update_courses">редактировать/<br />удалить курс</NavLink></li>
              <li><NavLink to="/adm/update_paid_videos">редактировать/<br />удалить видео</NavLink></li>
              <li><NavLink to="/adm/update_articles">редактировать/<br />удалить статью</NavLink></li>
              <li><NavLink to="/adm/new_tag">создать <br />тег</NavLink></li>
            </ul>
          </CSSTransition>

          <ButtonMobileMenu clickMenu={clickMenu} classna={classna} />
        </div>
      </div>

      <div className="menu_cases">
        <NavLink to="/adm/new_stories">New stories</NavLink>
        <NavLink to="/adm/update_club">О клубе</NavLink>
        <NavLink to="/adm/users">Пользователи</NavLink>
      </div>
    </>
  )
}

export { AdmNavbar }
