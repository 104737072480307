// защищённый маршрут

import { Navigate, Outlet } from "react-router-dom";
import { Buffer } from 'buffer' // декодирование токена


// компоненты


export const IndexProtectedRoute = () => {


  //Контекст
  const accessToken = localStorage.getItem("accessToken");



  if (!accessToken) {
    // Если нет токена, перенаправляем на страницу входа
    return <Navigate to="/register" />;


  } else {


    return <Navigate to="/my_cabinet/" />;
  }


};



