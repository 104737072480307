import { useContext } from 'react'
import { useNavigate } from "react-router-dom";
import { Outlet } from 'react-router-dom'
import { useState } from 'react'
import { Suspense } from 'react'
import { Link, useParams, useLocation } from 'react-router-dom';
import './cabinet_style.scss'
import logo from '../admin/adm_navbar/images/logo.svg'


// компоненты
import { AuthContext } from '../../context/authContext' // Контекст


const IndexLayout = () => {

  //Контекст
  const { logout, profile } = useContext(AuthContext);




  // -------------------- Имя и название курса ------------------------------- 

  const { url_course } = useParams(); // Узнаём url нашего курса
  const location = useLocation(); // хук для получения текущего URL


  // получаем конкретный курс из данных если он есть
  const title = profile.courses.find(course => course.url === url_course)?.title; // безопасный поиск

  // получаем username пользователя
  const namePerson = profile.username

  // Условие для отображения разных элементов в зависимости от URL
  const isMyCabinet = location.pathname === '/my_cabinet' || location.pathname === '/my_cabinet/';




  // --------------------Кнопка выйти ------------------------------- 


  //Навигация
  const navigate = useNavigate();


  function exit() {
    logout()

    //Переход в админку или в кабинет (c перезагрузкой)
    window.location.href = '/login';
    // navigate("/login", { replace: true });
  };



  return (
    <>
      {/* меню */}
      <div className='bg'>

        <div className='wrapper'>

          <div className='left_block'>
            <Link to="/my_cabinet" ><img src={logo} className="logoind" /> </Link>
            <div className='text'>

              {isMyCabinet ? (
                <>
                  <div className='cabinet'>Мой кабинет</div>
                  <div className='name'>{namePerson}</div>
                </>
              ) : (
                title && <div className='cabinet'>{title}</div> // проверяем на существование red
              )}

            </div>
          </div>


          {isMyCabinet ? (
            <>
              <div onClick={exit} className='logout'>Выйти</div>
            </>
          ) : (
            <Link to="/my_cabinet" className='my_cabinet'>
              <div className='cabinet'>Мой кабинет</div>
              <div className='circle'></div>
            </Link>
          )}

        </div>

      </div>


      <Suspense fallback={<h1>Loading...</h1>}>
        <Outlet />
      </Suspense>


      <div className='footer_cabinet_layout'>
        <div className='wrapper_cabinet_layout'>
          <a
            href="https://t.me/natalia_promolodost_assistant"
            className='link_problem'
          >
            Возникли проблемы?
          </a>

        </div>

      </div>


    </>
  )
}

export { IndexLayout }
