import { useNavigate } from "react-router-dom";
import { useState, useContext } from 'react'
import axios from "axios";
import './style.scss'
import { Link } from 'react-router-dom';

import logo from '../../../components/admin/adm_navbar/images/logo.svg'

// компоненты
import { AuthContext } from '../../../context/authContext' // Контекст



const Login = ({ url, success }) => {


  //Контекст
  const { saveTokens } = useContext(AuthContext);

  // on/off button
  // у кнопки изначально отключена настройка выключения кнопки
  const [isDisabled, setIsDisabled] = useState(false);


  //Навигация
  const navigate = useNavigate();



  //Логин пароль
  const [formNote, setFormNote] = useState({
    email: "",
    password: "",
  })






  // -----Функция отправки: (email, пароль) вход---------------------------------------
  // Отправка email пароль в django -> принимае токены -> cохраняем в контексте
  async function login(email, password) {

    try {
      setIsDisabled(true); // выключить кнопку 
      const otvet = await axios.post(`/api/token/`, {
        email,
        password
      })



      //сохраняем токены (ждём пока они сохраняться)
      await saveTokens(otvet.data)

      //Переход в админку или в кабинет (c перезагрузкой)
      window.location.href = url;

      //Переход в админку без перезагрузки
      // navigate("/adm/new_course", { replace: true });


    } catch (e) {
      console.log(`Login error ${e}`);

      if (e.response.status === 401) {
        // FIX: Нужно сделать сообщение пользователю если он неправильно ввёл пароль
        // Сообщаем пользователю что логин или пароль не верный
        // await props.setError('root.serverError', {
        //   type: e.response.status,
        //   message: 'Неверный e-mail или пароль. \n Попробуйте ввести заново',
        // })
      }
    }


  }





  //Функция получения логина пароля из формы в formNote
  function handleChange(event) {
    const { value, name } = event.target;

    // Если name === 'email', преобразуем value в нижний регистр
    const newValue = name === 'email' ? value.toLowerCase() : value;

    setFormNote(prevNote => ({
      ...prevNote,
      [name]: newValue
    }));
  }


  // если вход выполнен - на редиректит на my_cabinet
  if (success === 'вход уже выполнен') {
    navigate("/my_cabinet/", { replace: true });
    return (
      <div>redirect</div>
    )
  }



  return (
    <div className="bg_form">


      <div className='wrapper_form'>

        <div><img src={logo} className="lo" /> </div>

        <h3>Введите логин и пароль</h3>

        <form
          className="form_register"
          method="post" onSubmit={(e) => e.preventDefault()}
        >
          <input
            onChange={handleChange}
            text={formNote.email}
            name="email" type="text"
            className="input"
            maxLength="200"
            placeholder="Введите e-mail"
            value={formNote.email}
            required />
          <input
            onChange={handleChange}
            text={formNote.password}
            name="password"
            type="password"
            className="input"
            maxLength="200"
            placeholder="Введите пароль"
            value={formNote.password}
            required />

          <button
            onClick={() => login(formNote.email, formNote.password)}
            className="admin_button_cat"

            style={{
              backgroundColor: isDisabled ? 'grey' : 'green',
              pointerEvents: isDisabled ? 'none' : 'auto', // отключаем клик при отключенном состоянии
            }}
            disabled={isDisabled}
          >Войти</button>


          <Link
            className='link_problem'
            to="/register" >Регистрация
          </Link>

          <a
            href="https://t.me/natalia_promolodost_assistant"
            className='link_problem'
          >
            Возникли проблемы?
          </a>
        </form>

      </div>



    </div>
  )
};

export default Login;
