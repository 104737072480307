import { RouterProvider, createBrowserRouter, Link } from "react-router-dom";
import { AdminProtectedRoute } from "./AdminProtectedRoute";
import { UserProtectedRoute } from "./UserProtectedRoute";
import { IndexProtectedRoute } from "./IndexProtectedRoute";


// компоненты
import { AuthContext } from '../context/authContext' // Контекст

//Layout
import { TestLayout } from '../components/layout/TestLayout'
import { IndexLayout } from '../components/layout/IndexLayout'
import { AdmLayout } from '../components/layout/AdmLayout'

//Error
import { Notfoundpage } from '../pages/error/Notfoundpage'
import { NewTag } from '../pages/admin/tag/NewTag'


//Auth
import Login from '../pages/auth/login/Login';
import Register from '../pages/auth/register/Register';

//quiz
import Quiz from '../pages/quiz/Quiz';
import ProfileForm from '../pages/quiz/Quiz'
import Welcome from '../pages/quiz/Welcome'




const Routes = () => {

  //Контекст
  //!!!!!!!!!!!!!!!!!!!
  // const { accessToken } = useContext(AuthContext);
  const accessToken = localStorage.getItem("accessToken");




  // Маршруты доступные для всех пользователей
  const publicRoutes = [
    {
      path: "/",
      element: <IndexProtectedRoute />,
      children: [
        {
          path: "/",
          // element: <Indexpage />
          lazy: async () => {
            let { Indexpage } = await import("../pages/site/Indexpage")
            return { Component: Indexpage }
          }
        },
        {
          path: "*",
          element: <Notfoundpage />
        },

      ]
    },

    {
      path: "/service",
      element: <div>Service Page</div>,
    },


    // нет прав администратора
    {
      path: "/no_admin_right",
      lazy: async () => {
        let { NoAdminRight } = await import("../pages/auth/NoAdminRight")
        return { Component: NoAdminRight }
      }
    },


  ];


  // Маршруты для администратора
  const adminRoutes = [
    {
      path: "/",
      element: <AdminProtectedRoute />, // Оборачиваем компонент в ProtectedRoute если токена нет мы просто игнорируем 
      children: [


        {
          path: "/adm",
          element: <AdmLayout />,
          children: [

            {
              path: "", // Когда путь пустой, он ссылается на /adm/
              lazy: async () => {
                let { NewCourse } = await import("../pages/admin/course/NewCourse")
                // NOTE: Нужно добавить главную админки.
                return { Component: NewCourse }
              }
            },
            {
              path: "new_course",
              lazy: async () => {
                let { NewCourse } = await import("../pages/admin/course/NewCourse")
                return { Component: NewCourse }
              }
            },
            {
              path: "new_paid_video",
              lazy: async () => {
                let { NewPaidVideo } = await import("../pages/admin/paid_video/NewPaidVideo")
                return { Component: NewPaidVideo }
              }
            },
            {
              path: "new_article",
              lazy: async () => {
                let { NewArticle } = await import("../pages/admin/article/NewArticle")
                return { Component: NewArticle }
              }
            },
            {
              path: "new_tag",
              lazy: async () => {
                let { NewTag } = await import("../pages/admin/tag/NewTag")
                return { Component: NewTag }
              }
            },

            {
              path: "paid_video_success",
              lazy: async () => {
                let { PaidVideoSuccess } = await import("../pages/admin/paid_video/PaidVideoSuccess")
                return { Component: PaidVideoSuccess }
              }
            },
            {
              path: "course_success",
              lazy: async () => {
                let { CourseSuccess } = await import("../pages/admin/course/CourseSuccess")
                return { Component: CourseSuccess }
              }
            },
            {
              path: "article_success",
              lazy: async () => {
                let { ArticleSuccess } = await import("../pages/admin/article/ArticleSuccess")
                return { Component: ArticleSuccess }
              }
            },
            {
              path: "stories_success",
              lazy: async () => {
                let { StoriesSuccess } = await import("../pages/admin/stories/StoriesSuccess")
                return { Component: StoriesSuccess }
              }
            },
            {
              path: "update_courses",
              lazy: async () => {
                let { UpdateCourses } = await import("../pages/admin/course/UpdateCourses")
                return { Component: UpdateCourses }
              }
            },
            {
              path: "update_paid_videos",
              lazy: async () => {
                let { UpdatePaidVideos } = await import("../pages/admin/paid_video/UpdatePaidVideos")
                return { Component: UpdatePaidVideos }
              }
            },
            {
              path: "update_club",
              lazy: async () => {
                let { UpdateClub } = await import("../pages/admin/club/UpdateClub")
                return { Component: UpdateClub }
              }
            },
            {
              path: "update_articles",
              lazy: async () => {
                let { UpdateArticles } = await import("../pages/admin/article/UpdateArticles")
                return { Component: UpdateArticles }
              }
            },
            {
              path: "update_course/:id",
              lazy: async () => {
                let { UpdateCourse } = await import("../pages/admin/course/UpdateCourse")
                return { Component: UpdateCourse }
              }
            },
            {
              path: "update_paid_videos/:id",
              lazy: async () => {
                let { UpdatePaidVideo } = await import("../pages/admin/paid_video/UpdatePaidVideo")
                return { Component: UpdatePaidVideo }
              }
            },
            {
              path: "update_article/:id",
              lazy: async () => {
                let { UpdateArticle } = await import("../pages/admin/article/UpdateArticle")
                return { Component: UpdateArticle }
              }
            },
            {
              path: "new_stories",
              lazy: async () => {
                let { NewStories } = await import("../pages/admin/stories/NewStories")
                return { Component: NewStories }
              }
            },
            {
              path: "users",
              lazy: async () => {
                let { Users } = await import("../pages/admin/users/Users")
                return { Component: Users }
              }
            },
            {
              path: "users/:id",
              lazy: async () => {
                let { UserDetail } = await import("../pages/admin/users/UserDetail")
                return { Component: UserDetail }
              }
            },

          ]

        },


        {
          path: "/profile",
          element: <div>Профиль</div>,
        },

        // выход из системы
        {
          path: "/logout",
          lazy: async () => {
            let { Logout } = await import("../pages/auth/Logout")
            return { Component: Logout }
          }
        },




      ],
    },
  ];




  // Маршруты для кабинета
  const userRoutes = [
    {
      path: "/",
      element: <UserProtectedRoute />, // Оборачиваем компонент в ProtectedRoute если токена нет мы просто игнорируем 
      //всё что ниже и переходим в login/
      children: [

        {
          path: "/quiz",
          element: <ProfileForm />,
        },

        {
          path: "/welcome",
          element: <Welcome />,
        },

        {
          path: "/my_cabinet",
          element: <IndexLayout />,
          children: [

            {
              path: ":url_course/:url_paid_video",
              lazy: async () => {
                let { Course } = await import("../pages/cabinet/Course")
                return { Component: Course }
              }
            },

            {
              path: "",
              lazy: async () => {
                let { MyCabinet } = await import("../pages/cabinet/MyCabinet")
                return { Component: MyCabinet }
              }
            },
            {
              path: "users",
              lazy: async () => {
                let { Users } = await import("../pages/admin/users/Users")
                return { Component: Users }
              }
            }

          ]

        },
      ],
    },
  ];



  // Login - только для неавторизированных пользователей
  const LoginUnauthorized = [
    {
      path: "/login_admin",
      element: <Login
        url='/adm/new_course'
      />,
    },
    {
      path: "/login",
      element: <Login
        url='/my_cabinet'
      />,
    },
    {
      path: "/register",
      element: <Register />,
    },
  ];




  // Login - только для авторизированных пользователей
  const LoginSuccess = [
    {
      path: "/login_admin",
      element: <Login
        success='вход уже выполнен'
      />,
    },
    {
      path: "/login",
      element: <Login
        success='вход уже выполнен'
      />,
    },
    {
      path: "/register",
      element: <div>Вы уже зарегистрированны</div>,
    },
    // {
    //   path: "/quiz",
    //   element: <Quiz />,
    // },


  ];






  // Объединение и условное включение маршрутов на основе статуса аутентификации
  const router = createBrowserRouter([
    ...publicRoutes, // site

    ...(!accessToken ? LoginUnauthorized : LoginSuccess), // если токена нет включаются страницы login

    ...adminRoutes, // admin, если токен есть включается админка, если нет переход в login
    //сама логика прописанна в ProtectedRoute

    ...userRoutes, // кабинет user
  ]);

  // Представляем конфигурацию маршрутизатора с помощью RouterProveder
  return <RouterProvider router={router} fallbackElement={<p>Loading.....</p>} />;
};

export default Routes;

