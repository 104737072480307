// защищённый маршрут

import { useContext } from 'react';
import { Navigate, Outlet } from "react-router-dom";
import { Buffer } from 'buffer' // декодирование токена


// компоненты
// import { AuthContext } from '../context/authContext' // Контекст


export const AdminProtectedRoute = () => {


  //Контекст
  // const { accessToken } = useContext(AuthContext);
  const accessToken = localStorage.getItem("accessToken");



  if (!accessToken) {
    // Если нет токена, перенаправляем на страницу входа
    return <Navigate to="/login_admin" />;


  } else {
    // Если токен есть, расшифровываем ключ
    const Token = Buffer.from(accessToken.split('.')[1], 'base64').toString('ascii');
    const tokenData = JSON.parse(Token); // Преобразуем строку в объект


    // Если пользователь не является суперпользователем то блокируем админку и выходим
    if (tokenData.superuser == false) {
      return <Navigate to="/no_admin_right" />;
    }
  }



  // Отображаем дочерние маршруты
  return <Outlet />;
};



