// Форма
import { useForm } from 'react-hook-form';
// инпут
import { Controller } from 'react-hook-form'; // контроллер для инпута







const PhoneQuiz = ({ isDisabled, handleChange }) => {


  // -----Формы-----
  // настройки для формы
  const { register,
    control,
    handleSubmit,
    setError,
    formState: { errors }

  } = useForm({
    mode: 'onBlur',
  });




  return (

    <>
      <div className='dots_quiz'>
        <div className='dot'></div>
        <div className='dot'></div>
        <div className='dot_active'></div>
      </div>

      <div className='title_quiz'>
        Дата рождения:
      </div>

      {/* контроллер от формы */}
      <Controller
        control={control}
        name='birth_date'
        render={({ field: { onChange, onBlur, value = '' } }) => (



          <input
            className='input_quiz'
            type='date'
            onBlur={onBlur}

            {...register('birth_date', {
              required: 'Введите дату рождения',
              minLength: {
                value: 2,
                message: 'Введите дату рождения'
              }
            })
            }

            onChange={onChange}
            value={value}
            placeholder={'00.00.0000'}
          />

        )}
      />

      {/*ошибка*/}
      {errors?.date && <div className='error_quiz'>
        {errors?.date?.message}
      </div>}

      <button
        onClick={handleSubmit(handleChange)}
        className='button_quiz'
        disabled={isDisabled}>
        Далее
      </button>


    </>


  );
};

export default PhoneQuiz;





























