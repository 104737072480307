import { Link } from 'react-router-dom';
import { useState, useEffect } from "react";
import New from './Par'
import { CSSTransition } from 'react-transition-group'
import TokenManagerMiddleware from '../../../../context/TokenManagerMiddleware'

const DelJob = ({ url, del_url, name, danger }) => {



  const [notes, setNewNotes] = useState(null)
  const [pop_del, setPopDel] = useState(false)
  const [id, setId] = useState("")

  const clickPop = (ev, add) => {
    setPopDel(!pop_del)
    setId(add)
  }
  const clickDel = () => {
    setPopDel(!pop_del)
  }


  useEffect(() => {
    getNotes()
  }, [])

  function getNotes() {

    // отправляем запрос через middleware (не требуется ключ админа)
    TokenManagerMiddleware({
      method: "GET",
      url: url,
    }).then((response) => {
      const data = response.data
      setNewNotes(data)
    }).catch((error) => {
      if (error.response) {
        console.log(error.response);
        console.log(error.response.status);
        console.log(error.response.headers);
      }
    })
  }



  const delNotes = () => {
    TokenManagerMiddleware({
      method: "DELETE",
      url: del_url + id,
    }).then((response) => {
      getNotes()
      clickDel()
    }
    )
  }


  return (
    <div className="container_list_article">

      <h3>{name}</h3>

      <CSSTransition in={pop_del} timeout={300}
        classNames='alert' unmountOnExit>


        <div className="pop_up_del">
          {danger}
          <button onClick={delNotes} className="admin_button_red">Удалить</button>
          <button onClick={clickDel} className="admin_button_cat">Отменить</button>
        </div>

      </CSSTransition>

      <div className="del_article">

        {notes && notes.map(note => <New
          key={note.id}
          id={note.id}

          title={note.title}
          url_partner={note.url_partner}
          intro={note.intro}

          clickPop={clickPop}
          delNotes={delNotes}
        />
        )}

      </div>
    </div>
  )
}

export { DelJob }
