import { InputTag } from '../../../components/admin/tag/InputTag'
import { DelJob } from '../../../components/admin/shared/del_job/DelJob'

const NewTag = () => {

  return (
    <>
      <InputTag />
      <DelJob url="../api/tag/"
        del_url="../api/del_tag/"
        name="Существующие теги"
        danger="Вы действительно хотите удалить этот тег? Будьте осторожны, тег удалиться из всех курсов которые отмечены этим тегом."
      />
    </>
  )
}

export { NewTag }
