import React, { useEffect, useRef } from "react";
import { Link, NavLink } from 'react-router-dom'
import axios from "axios";

function Par({ delNotes, title, url, id, clickPop }) {

  return (
    <div className="product2">
      <div className="del" onClick={(ev) => clickPop(ev, id)}><img src="/img/del.svg" /></div>
      <a>{title}</a>
    </div>
  )
}

export default Par;
