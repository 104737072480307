// защищённый маршрут для кабинета пользователя
import { useContext } from 'react';
import { Navigate, Outlet } from "react-router-dom";

import { Buffer } from 'buffer' // декодирование токена


// компоненты
import { AuthContext } from '../context/authContext' // Контекст


export const UserProtectedRoute = () => {


  //Контекст
  // const { accessToken } = useContext(AuthContext);
  const accessToken = localStorage.getItem("accessToken");





  // Если нет токена, перенаправляем на страницу login
  if (!accessToken) {
    return <Navigate to="/login" />;
  }


  // Отображаем дочерние маршруты
  return <Outlet />;
};



