import './style.scss'
import { Outlet } from 'react-router-dom'
import { AdmNavbar } from '../admin/adm_navbar/AdmNavbar'
import { Suspense } from 'react'

const AdmLayout = () => {


  return (
    <>
      <AdmNavbar />

      <Suspense fallback={<h1>Loading...</h1>}>
        <Outlet />
      </Suspense>
    </>
  )
}

export { AdmLayout }
